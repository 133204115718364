.notifMain {
  
  .content{
    width:100%;
    height:100%;
    @include flexed(column);
  }
  
  .divider {
    margin:30px 0;
  }
  
  .notifBox {
    position: relative;
    background-color:#FFFFFF;
    border-radius:10px;
    max-height:95%;
    max-width:90%;
    
    .notifContent {
      margin:auto;
      overflow: auto;
      max-height:100%;
      width:100%;
      padding:10px;
      overflow-wrap:break-word;
      
      .notifHead {
        font-size:18px !important;
        flex:1 1 200px;
      }
      .notifComp {
        font-size:18px !important;
        flex:1 1 0;
      }
    }
  }
  
  
}