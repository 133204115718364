.survmain {
  @include fulldim;
  @include flexed(row);
  align-items: flex-end;
  border-radius:0 0 10px 10px;
  
  .survdesc {
    text-align:center;
    font-size:18px;
    flex:7;
    background-color:orange;
    color:#FFF;
    padding:10px;
    border-radius:0 0 0 10px;
  }
  .survdescbuts {
    padding:10px;
    border-radius:0 0 10px 0;
    text-align:center;
    height:100%;
    color:#FFF;
  }
}

.survgifts {
  @include abs(topright, 0, 0);
  background-color: #00b5ad;
  display:inline-block;
  color:#FFF;
  border-radius: 0 0 0 10px;
  padding:7px;
  font-size:16px;
  i.icon {
    margin:0 0 0 5px;
  }
}