$circ:12px;
.carouselMain {
  @include fulldim;
  overflow:hidden;
  
  .left {
    @include fulldim(1/8, 1);
    @include abs;
  }
  
  .right {
    @include fulldim(1/8, 1);
    @include abs(right);
  }
  
  .carouselBulletsCont {
    @include flexed(row);
    @include abs(topcenter, 0, 10px);
    .carouselBullets {
      display:inline-block;
      background-color:rgba(150,150,150,0.5);
      width:$circ;
      height:$circ;
      margin:5px;
      border-radius:$circ;
    }
    .active {
      background-color:rgba(230,230,230,0.8);
    }
  }
  
  .carouselslide {
    @include fulldim;
    
    .chat {
      @include abs(bottomright);
      padding:10px;
      background-color:#FFF;
      @include flexed(row);
      border-radius: 10px 0 0 0;
      max-width:90%;
      
      #clip {
        width:auto;
        border:none;
        @include fixed;
        top:-999px;
        left:-999px;
      }
      
      .blocktxt {
        display:inline-block;
        font-size:18px;
        font-weight: bold;
      }
      
      .chatdesc {
        color:#666;
      }
      
      .chaticon {
        
      }
    }
    
    .carouselbgs {
      @include bgcover;
      @include fulldim;
      @include abs;
      @include flexed;
      
      .bgtexts {
        background-color:rgba(255,255,255,0.9);
        color:#333333;
        padding:10px;
        border-radius:10px;
        text-align: center;
        max-width:80%;
        
        .title {
          font-weight:bold;
          font-size:24px;
        }
        
        .description {
          margin-top:10px; 
        }
      }
    }
  }
  
}
