.rewardsmain{
  @include fulldim(1, auto);
  @include abs;
  min-height: 100%;
  background-color:#F6F6F6;
  text-align: center;

  .rcmain {
    margin:30px auto;
    @include fulldim(0.8, auto);

    .rcpreview {
      @include fulldim(1, auto);
      height:300px;
      @include bgcover;
      border-radius:10px 10px 0 0;
    }
    
    .rcpromo {
      padding:10px;
      display:inline-block;
      background-color:#FFF;
      border-radius:50px;
      font-size:18px;
      margin-left:10px;
    }
    
    .rcpts {
      border-radius:0 0 10px 10px;
      color:#FFF;
      padding:20px;
      @include flexed(row);
      
      .rcptsbuts {
        height:100%;
        padding:10px;
      }
    }

    .rcname {
      background-color:#FFF;
      font-size:18pt;
      padding:20px;
      text-transform: uppercase;
      line-height: 25px;
    }
  }
}