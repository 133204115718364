@mixin bgcover {
  background-size:cover;
  background-position:center;
}

@mixin fulldim($mulx:1, $muly:1) {
  @if $mulx=="auto"{
    width:auto;
  } @else {
    width:100%*$mulx;
  }
  @if $muly=="auto"{
    height:auto;
  } @else {
    height:100%*$muly;
  }
  position:relative;
}

@mixin fixed {
  position:fixed;
  left:0;
  top:0;
}

@mixin abs($pos:left, $val1:0, $val2:0) {
  @if $pos=="right" {
    right:$val1;
    top:$val2;
  }
  
  @if $pos=="left" {
    left:$val1;
    top:$val2;
  }
  
  @if $pos=="bottomright" {
    right:$val1;
    bottom:$val2;
  }
  
  @if $pos=="topright" {
    right:$val1;
    top:$val2;
  }
  
  @if $pos=="topcenter" {
    right:$val1;
    left:$val1;
    margin:auto;
    top:$val2;
  }
  
  position:absolute;
}

@mixin flexed($dir:column) {
  display:flex;
  flex-direction: $dir;
  justify-content: center;
  align-items: center;
}