.menu {
  @include fulldim;
  @include flexed(row);
  
  .item {
    flex:1;
    height:100%;
    @include flexed(column);
    div {
      font-size:10pt;
      font-weight: 400;
      color:#999;
    }
  }
  
  .active {
    background-color:#444444;
    div {
      color:#FFF;
    }
  }
}