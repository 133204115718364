@import "./mixins.scss";
@import "./Promo.scss";
@import "./Menu.scss";
@import "./Carousel.scss";
@import "./Edit.scss";
@import "./Rewards.scss";
@import "./Surveys.scss";
@import "./Notify.scss";

$baseWidth:100%;
$baseHeight:100%;

html,body{
  height:100%;
  width:100%;
}
.app {
  height:$baseHeight;
  width:$baseWidth;
  @include fixed;
  @include flexed(column);
  overflow:hidden;
  
  .loadScreen {
    @include fulldim(1, 1);
    @include fixed;
    @include flexed(column);
    background-color:rgba(255,255,255,0.3);
    z-index: 99999;
  }
  
  .upper {
    overflow:auto;
    @include fulldim(1, auto);
    flex:10 1 80%;
    .main {
      @include fulldim;
      @include flexed(column);
    }
  }
  
  .lower {
    flex:1 1 75px;
    @include fulldim(1, auto);
    @include flexed(row);
  }
  
  .totalgifts {
    @include abs(left, 0, 0);
    z-index:9998;
    background-color: #00b5ad;
    display:inline-block;
    color:#FFF;
    border-radius: 0 0 10px 0;
    padding:7px;
    font-size:16px;
    i.icon {
      margin:0 0 0 5px;
    }
  }
  
  .redeemMain {
    @include fulldim;
    @include flexed(column);

    .lottieAnim {
      @include fixed;
      width:100%;
      height:100%;
      z-index:9997;
      background-color: #ffffff;
      @include flexed(column);
      opacity:0;
      transition:opacity 0.3s;
    }
    
    .redeemMsg {
      max-width:80%;
      padding:15px;
      font-size:18px;
      line-height: 25px;
      color:rgba(255,255,255,0.9);
      font-weight: bolder;
      border-radius:10px;
      text-align: center;
    }
    
    .redeemHash {
      margin-top:10px;
      font-size:21px;
      color:#555555;
    }
  }
}
/*
@media screen and (orientation: landscape) {
  #lock{
    @include fulldim;
    @include flexed(column);
    @include fixed;
    z-index:9999;
    background-color:rgba(255,255,255,0.9);
    font-size:18pt;
    color:#666;
    div {
      margin:20px;
    }
  }
}
*/


@media screen and (orientation: portrait) {
  #lock{
    display:none;
  }
}